// src/components/site/ContactPage.js
import React from 'react';

const ContactPage = () => {
  return (
    <div style={{ color: 'white', padding: '20px' }}>
      <h2>Contact Us</h2>
      <p>Contact us at contact@vatloom.com.</p>
    </div>
  );
};

export default ContactPage;
