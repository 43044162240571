import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import './FlightsPage.css';
import airportsData from '../../assets/data/airports.csv';

const FlightsPage = () => {
  const [flights, setFlights] = useState([]);
  const [airports, setAirports] = useState({});
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'callsign', direction: 'ascending' });

  useEffect(() => {
    Papa.parse(airportsData, {
      download: true,
      header: true,
      complete: (result) => {
        const airportsByICAO = result.data.reduce((acc, airport) => {
          acc[airport.icao] = airport;
          return acc;
        }, {});
        setAirports(airportsByICAO);
      },
    });

    const fetchData = async () => {
      try {
        const response = await axios.get('https://data.vatsim.net/v3/vatsim-data.json');
        setFlights(response.data.pilots);
      } catch (error) {
        setError('Failed to fetch flight data');
        console.error('Error fetching flight data:', error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 15000);
    return () => clearInterval(interval);
  }, []);

  const getValueByKey = (flight, key) => {
    const keys = key.split('.');
    let value = flight;
    for (let k of keys) {
      value = value ? value[k] : null;
    }
    return value;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedFlights = [...flights].sort((a, b) => {
    const aValue = getValueByKey(a, sortConfig.key) || '';
    const bValue = getValueByKey(b, sortConfig.key) || '';

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'ascending'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (aValue < bValue) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
        return (
            <span
                className={`sort-caret ${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}
            />
        );
    }
    return null;
  };

  if (error) {
    return <div className="flights-container">{error}</div>;
  }

  const formatAirportInfo = (icao) => {
    const airport = airports[icao];
    if (!airport) return null;

    return (
      <div className="airport-info">
        <div className="icao-box">
          <div className="icao">{icao}</div>
        </div>
        <div className="airport-details">
          <div>{airport.name}</div>
          <div>{`${airport.city}, ${airport.subd || ''}, ${airport.country}`}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="flights-container">
      <h2 className="table-title">Active Flights</h2>
      <div className="flights-table-container">
        <table className="flights-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('callsign')}>Callsign{getSortIndicator('callsign')}</th>
              <th onClick={() => handleSort('name')}>Pilot{getSortIndicator('name')}</th>
              <th onClick={() => handleSort('flight_plan.aircraft_short')}>Aircraft{getSortIndicator('flight_plan.aircraft_short')}</th>
              <th onClick={() => handleSort('flight_plan.departure')}>Departure{getSortIndicator('flight_plan.departure')}</th>
              <th onClick={() => handleSort('flight_plan.arrival')}>Arrival{getSortIndicator('flight_plan.arrival')}</th>
              <th onClick={() => handleSort('altitude')} className="centered-column">Altitude (Ft){getSortIndicator('altitude')}</th>
              <th onClick={() => handleSort('groundspeed')} className="centered-column">Speed (Kts){getSortIndicator('groundspeed')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedFlights.map((flight, index) => (
              <tr key={index}>
                <td>{flight.callsign}</td>
                <td>{flight.name}</td>
                <td>{flight.flight_plan?.aircraft_short || 'N/A'}</td>
                <td>{formatAirportInfo(flight.flight_plan?.departure || 'N/A')}</td>
                <td>{formatAirportInfo(flight.flight_plan?.arrival || 'N/A')}</td>
                <td className="centered-data">{Math.round(flight.altitude / 100) * 100}</td>
                <td className="centered-data">{flight.groundspeed}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FlightsPage;
