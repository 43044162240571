import './aircraftInfoPopup.css';

const aircraftInfoPopup = (pilot) => {
    if (!pilot || !pilot.callsign) {
      return `<div>
        <strong>Unknown Aircraft</strong>
        <p>Details not available</p>
      </div>`;
    }
  
    // Display information only if available, using safe checks
    const callsign = pilot.callsign || 'Unknown';
    const aircraftType = pilot.flight_plan?.aircraft_short || 'Unknown';
    const departure = pilot.flight_plan?.departure || 'Unknown';
    const arrival = pilot.flight_plan?.arrival || 'Unknown';
    const altitude = pilot.altitude;
    const groundspeed = pilot.groundspeed;
  
    return `
      <div>
        <strong>Callsign:</strong> ${callsign}<br>
        <strong>Aircraft Type:</strong> ${aircraftType}<br>
        <strong>Departure:</strong> ${departure}<br>
        <strong>Arrival:</strong> ${arrival}<br>
        <strong>Altitude:</strong> ${altitude} ft<br>
        <strong>Groundspeed:</strong> ${groundspeed} knots
      </div>`;
  };
  

  export default aircraftInfoPopup;