// src/components/site/AboutPage.js
import React from 'react';

const AboutPage = () => {
  return (
    <div style={{ color: 'white', padding: '20px' }}>
      <h2>About VatLoom</h2>
      <p>This page contains information about VatLoom.</p>
    </div>
  );
};

export default AboutPage;
