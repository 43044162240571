import { aircraftIcons } from './aircraftIcons';

export const preloadIcons = (map) => {
  Object.entries(aircraftIcons).forEach(([key, icon]) => {
    const img = new Image();
    img.src = icon;
    img.onload = () => {
      if (!map.hasImage(key)) {
        map.addImage(key, img, { sdf: true });
        console.log(`Icon loaded: ${key}`);
      }
    };
    img.onerror = () => {
      console.error(`Failed to load icon for: ${key}`);
    };
  });
};
