import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { preloadIcons } from '../../assets/images/preloadIcons';
import { aircraftTypeMappings, aircraftIcons, aircraftSizeMapping, aircraftSizeClassifications } from '../../assets/images/aircraftIcons';
import aircraftInfoPopup from './aircraftInfoPopup.js';
import './VatsimMap.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// Get aircraft icon and scale factor
const getAircraftIcon = (aircraftType, heading) => {
  const mappedType = aircraftType ? aircraftTypeMappings[aircraftType?.toUpperCase()] || 'defaultIcon' : 'defaultIcon';
  const iconPath = aircraftIcons[mappedType] || aircraftIcons['defaultIcon'];
  const sizeClass = aircraftSizeMapping[mappedType] || 'small';
  const scaleFactor = aircraftSizeClassifications[sizeClass] || 1;
  const adjustedHeading = (heading || 0) % 360; // Normalize heading
  return { iconPath, scaleFactor, heading: adjustedHeading };
};

// Create plane element
const createPlaneElement = (iconPath, scaleFactor) => {
  const planeIcon = document.createElement('img');
  planeIcon.src = iconPath;
  const baseSize = 50;

  planeIcon.onload = () => {
    const aspectRatio = planeIcon.naturalWidth / planeIcon.naturalHeight;
    planeIcon.style.width = `${baseSize * scaleFactor * aspectRatio}px`;
    planeIcon.style.height = `${baseSize * scaleFactor}px`;
  };

  return planeIcon;
};

const VatsimMap = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef({});
  const [pilots, setPilots] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchVatsimData = async () => {
    try {
      const response = await axios.get('https://data.vatsim.net/v3/vatsim-data.json');
      setPilots(response.data.pilots);
      setLoading(false);
      console.log("VATSIM data fetched.");
    } catch (err) {
      console.error('Error fetching VATSIM data:', err);
      setLoading(false);
    }
  };

  // Update markers in view
  const updateMarkersInView = useCallback(() => {
    const bounds = mapRef.current.getBounds();
    pilots.forEach((pilot) => {
      const { callsign, longitude, latitude, heading, flight_plan } = pilot;
      if (!bounds.contains([longitude, latitude])) {
        if (markersRef.current[callsign]) {
          markersRef.current[callsign].remove();
          delete markersRef.current[callsign];
        }
        return;
      }
      const { iconPath, scaleFactor } = getAircraftIcon(flight_plan?.aircraft_short, heading);
      if (markersRef.current[callsign]) {
        markersRef.current[callsign].setLngLat([longitude, latitude]);
        markersRef.current[callsign].getElement().style.transform = `rotate(${heading}deg)`;
      } else {
        const markerElement = createPlaneElement(iconPath, scaleFactor);
        const marker = new mapboxgl.Marker({ element: markerElement, rotation: heading, rotationAlignment: 'map' })
          .setLngLat([longitude, latitude])
          .addTo(mapRef.current);
        const popupContent = aircraftInfoPopup(pilot);
        const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent);
        marker.getElement().addEventListener('click', () => {
          popup.addTo(mapRef.current);
          marker.setPopup(popup);
        });
        markersRef.current[callsign] = marker;
      }
    });
  }, [pilots]);

  useEffect(() => {
    fetchVatsimData();
    const interval = setInterval(fetchVatsimData, 10000);  // Fetch data every 10 seconds
    return () => clearInterval(interval);
  }, []);

  // Initialize map and add markers
  useEffect(() => {
    if (!mapRef.current && mapContainerRef.current) {
      console.log("Initializing map...");
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/wjrob/cm1ayv95x01m401p4cbykca3k',
        center: [0, 30],
        zoom: 2.5,
        maxBounds: [[-180, -85], [180, 85]],
      });

      mapRef.current.on('style.load', () => {
        console.log("Map style loaded. Preloading icons and updating markers...");
        preloadIcons(mapRef.current);
        updateMarkersInView();
      });

      mapRef.current.on('moveend', () => {
        fetchVatsimData();
      });
    }

    if (!loading && mapRef.current) {
      updateMarkersInView();
    }
  }, [pilots, loading, updateMarkersInView]);

  if (loading) return <div>Loading map and data...</div>;

  return (
    <div ref={mapContainerRef} style={{ height: '100vh', width: '100%' }}>
      {/* Map rendering here */}
    </div>
  );
};

export default VatsimMap;
