// In aircraftIcons.js
import A300Icon from './aircraftIcons/A300.png';
import A310Icon from './aircraftIcons/A310.png';
import A319Icon from './aircraftIcons/A319.png';
import A320Icon from './aircraftIcons/A320.png';
import A321Icon from './aircraftIcons/A321.png';
import A332Icon from './aircraftIcons/A332.png';
import A333Icon from './aircraftIcons/A333.png';
import A343Icon from './aircraftIcons/A343.png';
import A346Icon from './aircraftIcons/A346.png';
import A359Icon from './aircraftIcons/A359.png';
import A388Icon from './aircraftIcons/A388.png';
import AT4xIcon from './aircraftIcons/AT4x.png';
import AT7xIcon from './aircraftIcons/AT7x.png';
import B38MIcon from './aircraftIcons/B38M.png';
import B46xIcon from './aircraftIcons/B46x.png';
import B77LIcon from './aircraftIcons/B77L.png';
import B77WIcon from './aircraftIcons/B77W.png';
import B78XIcon from './aircraftIcons/B78X.png';
import B703Icon from './aircraftIcons/B703.png';
import B712Icon from './aircraftIcons/B712.png';
import B722Icon from './aircraftIcons/B722.png';
import B733Icon from './aircraftIcons/B733.png';
import B734Icon from './aircraftIcons/B734.png';
import B736Icon from './aircraftIcons/B736.png';
import B737Icon from './aircraftIcons/B737.png';
import B738Icon from './aircraftIcons/B738.png';
import B739Icon from './aircraftIcons/B739.png';
import B742Icon from './aircraftIcons/B742.png';
import B744Icon from './aircraftIcons/B744.png';
import B748Icon from './aircraftIcons/B748.png';
import B752Icon from './aircraftIcons/B752.png';
import B753Icon from './aircraftIcons/B753.png';
import B762Icon from './aircraftIcons/B762.png';
import B772Icon from './aircraftIcons/B772.png';
import B773Icon from './aircraftIcons/B773.png';
import B788Icon from './aircraftIcons/B788.png';
import B789Icon from './aircraftIcons/B789.png';
import BE58Icon from './aircraftIcons/BE58.png';
import C25CIcon from './aircraftIcons/C25C.png';
import C130Icon from './aircraftIcons/C130.png';
import C152Icon from './aircraftIcons/C152.png';
import C172Icon from './aircraftIcons/C172.png';
import C182Icon from './aircraftIcons/C182.png';
import C208Icon from './aircraftIcons/C208.png';
import C700Icon from './aircraftIcons/C700.png';
import C750Icon from './aircraftIcons/C750.png';
import CL60Icon from './aircraftIcons/CL60.png';
import CRJ9Icon from './aircraftIcons/CRJ9.png';
import DA40Icon from './aircraftIcons/DA40.png';
import DA42Icon from './aircraftIcons/DA42.png';
import DA62Icon from './aircraftIcons/DA62.png';
import DC3Icon from './aircraftIcons/DC3.png';
import DC6Icon from './aircraftIcons/DC6.png';
import DH8DIcon from './aircraftIcons/DH8D.png';
import DHC2Icon from './aircraftIcons/DHC2.png';
import E17xIcon from './aircraftIcons/E17x.png';
import E19xIcon from './aircraftIcons/E19x.png';
import E55PIcon from './aircraftIcons/E55P.png';
import E75xIcon from './aircraftIcons/E75x.png';
import EC45Icon from './aircraftIcons/EC45.png';
import F14Icon from './aircraftIcons/F14.png';
import F28Icon from './aircraftIcons/F28.png';
import F35Icon from './aircraftIcons/F35.png';
import GLF5Icon from './aircraftIcons/GLF5.png';
import H160Icon from './aircraftIcons/H160.png';
import MD8xIcon from './aircraftIcons/MD8x.png';
import MD11Icon from './aircraftIcons/MD11.png';
import PC12Icon from './aircraftIcons/PC12.png';
import R66Icon from './aircraftIcons/R66.png';
import SF50Icon from './aircraftIcons/SF50.png';
import T134Icon from './aircraftIcons/T134.png';
import T154Icon from './aircraftIcons/T154.png';
import TBM8Icon from './aircraftIcons/TBM8.png';
import TBM9Icon from './aircraftIcons/TBM9.png';
// New Icons
import CONCIcon from './aircraftIcons/CONC.png';
import SR22Icon from './aircraftIcons/SR22.png';
import SR71Icon from './aircraftIcons/SR71.png';
import EUFIIcon from './aircraftIcons/EUFI.png';

// Default Icon
import defaultIconImg from './defaultIcon.png';

const aircraftTypeMappings = {
    'A300': 'A300',
    'A306': 'A300',
    'A310': 'A310',
    'A319': 'A319',
    'A320': 'A320',
    'A321': 'A321',
    'A332': 'A332',
    'A333': 'A333',
    'A343': 'A343',
    'A346': 'A346',
    'A359': 'A359',
    'A388': 'A388',
    'AT4x': 'AT4x',
    'AT46': 'AT4x',
    'AT7x': 'AT7x',
    'B38M': 'B38M',
    'B46x': 'B46x',
    'B77L': 'B77L',
    'B77W': 'B77W',
    'B78X': 'B78X',
    'B703': 'B703',
    'B712': 'B712',
    'B722': 'B722',
    'B733': 'B733',
    'B734': 'B734',
    'B736': 'B736',
    'B737': 'B737',
    'B738': 'B738',
    'B739': 'B739',
    'B742': 'B742',
    'B744': 'B744',
    'B748': 'B748',
    'B752': 'B752',
    'B753': 'B753',
    'B762': 'B762',
    'B772': 'B772',
    'B773': 'B773',
    'B788': 'B788',
    'B789': 'B789',
    'BE58': 'BE58',
    'C25C': 'C25C',
    'C25B': 'C25C',
    'C130': 'C130',
    'C152': 'C152',
    'C172': 'C172',
    'C182': 'C182',
    'C208': 'C208',
    'C414': 'C208',
    'C700': 'C700',
    'C750': 'C750',
    'CL60': 'CL60',
    'CRJ9': 'CRJ9',
    'DA40': 'DA40',
    'DA42': 'DA42',
    'DA62': 'DA62',
    'DC3': 'DC3',
    'DC6': 'DC6',
    'DH8D': 'DH8D',
    'DHC2': 'DHC2',
    'E17x': 'E17x',
    'E19x': 'E19x',
    'E121': 'E17x',
    'E55P': 'E55P',
    'E75x': 'E75x',
    'E75L': 'E75x',  // Use E75x for E75L
    'EC45': 'EC45',
    'F14': 'F14',
    'F28': 'F28',
    'F35': 'F35',
    'GLF5': 'GLF5',
    'GLF6': 'GLF5',
    'H160': 'H160',
    'H145': 'H160',
    'MD8x': 'MD8x',
    'MD11': 'MD11',
    'PC12': 'PC12',
    'P06T': 'PC12',
    'P46T': 'PC12',
    'R66': 'R66',
    'R44': 'R66',  // Use R66 for R44
    'SF50': 'SF50',
    'T134': 'T134',
    'T154': 'T154',
    'TBM8': 'TBM8',
    'TBM9': 'TBM9',
    'CONC': 'CONC',
    'SR22': 'SR22',
    'SR71': 'SR71',
    'B73W': 'B737',   
    '777': 'B77W',    
    'B777': 'B77W',   
    'B77F': 'B77W',   
    'E170': 'E17x',   
    'E175': 'E75x',   
    'E190': 'E19x',   
    'E195': 'E19x',   
    'A21N': 'A321',   
    'A20N': 'A320',   
    'A318': 'A319',   
    'A32N': 'A320',   
    'AT76': 'AT7x',   
    'B462': 'B46x',   
    'B463': 'B46x',   
    'C550': 'C25C',   
    'MD82': 'MD8x',   
    'MD83': 'MD8x',   
    'MD88': 'MD8x',   
    'PA34': 'PC12',   
    'PA24': 'C172',   
    'PA28': 'C172',   
    'P28R': 'C172',   
    'B350': 'BE58',   
    'A339': 'A333',   // Use A333 for A339
    'C207': 'C208',   // Use C208 for C207
    'BE20': 'BE58',   // Use BE58 for BE20
    'BE60': 'BE58',   // Use BE58 for BE60
    'Unknown': 'defaultIcon',  
    'unknown': 'defaultIcon',
    'PRPR': 'defaultIcon',     
    'KODI': 'C208', // Use C208 for KODI
    'BE36': 'C172', // Use C172 for BE36
    'C337': 'defaultIcon',     
    'B1': 'defaultIcon',       
    'SU27': 'F14',     
    'HDJT': 'C750',     // Use C750 for HDJT
    'AN24': 'defaultIcon',     
    'C170': 'C172',     // Use C172 for C170
    'H075': 'defaultIcon',     
    'H47': 'H160',     // Use H160 for H47 
    'C25A': 'C25C',     // Use C25C for C25A    
    'SU95': 'E75x',     // Fallback to default
    'EUFI': 'EUFI',     // Fallback to default
    'LEA4': 'CL60',     // Use CL60 for LEA4
    'MG21': 'defaultIcon',     // Fallback to default
    'YK40': 'CRJ9',     // Use CRJ9 for YK40
    'BE76': 'C172',            // Closest available icon
    'B60T': 'BE58',            // Closest available icon
    'BCS3': 'A320',
    'F16': 'F35',
    'H250': 'H160',
    'CRJ7': 'CRJ9',
    'BE35': 'BE58',
    'C510': 'C25C',
    'T144': 'T154',
    'EC35': 'EC45',
    'CH47': 'H160',
    'P28A': 'C172',
    'G21': 'DA40',
    'LJ35': 'C25C',
    'B763': 'B762',
    'E110': 'DHC2',
    'AT45': 'AT4x',
    '738': 'B738',
    'H60': 'EC45',
    'E50P': 'E55P',
    'E75S': 'E75x',
    'E145': 'E17x',
    'SF34': 'DH8D',
    'AS50': 'EC45',
    'BN2P': 'DHC2',
    'A169': 'H160',
    'BE30': 'BE58',
    'B190': 'DH8D',
    'F22': 'F35',
    '320N': 'A320',
    'GLEX': 'GLF5',
    'CC43': 'C182',
    'B764': 'B762',
    'UH60': 'H160',
    '777W': 'B77W',
    'C525': 'C25C',
    'CL30': 'CL60',
    'E314': 'defaultIcon',
    'E35L': 'E55P',
    'AT75': 'AT7x',
    'SH36': 'DH8D',
    'B461': 'B733',
    'A340': 'A343',
    'G2CA': 'GLF5',
    'RV10': 'C182',
    'PA39': 'BE58',
    'S22T': 'SR22',
    'DR40': 'DA40',
    'M339': 'F28',
    'F18': 'F14',
    'CV43': 'B732',
    'LEAR': 'CL60',
    'B787': 'B788',
    'M20T': 'C182',
    'F18S': 'F14',
    'A109': 'H160',
    'C205': 'C208',
    'DA50': 'DA40',
    'A330': 'A332',
    '7378': 'B738',
    'CJ4': 'C700',
    'R19R': 'EC45',
    'AS65': 'EC45',
    'B39M': 'B38M',
    'C206': 'C208',
    'AT72': 'AT7x',
    'F15': 'F14',
    'A35K': 'A359',
    'PC6P': 'C172',

  };

const aircraftIcons = {
  'A300': A300Icon,
  'A310': A310Icon,
  'A319': A319Icon,
  'A320': A320Icon,
  'A321': A321Icon,
  'A332': A332Icon,
  'A333': A333Icon,
  'A343': A343Icon,
  'A346': A346Icon,
  'A359': A359Icon,
  'A388': A388Icon,
  'AT4x': AT4xIcon,
  'AT7x': AT7xIcon,
  'B38M': B38MIcon,
  'B46x': B46xIcon,
  'B77L': B77LIcon,
  'B77W': B77WIcon,
  'B78X': B78XIcon,
  'B703': B703Icon,
  'B712': B712Icon,
  'B722': B722Icon,
  'B733': B733Icon,
  'B734': B734Icon,
  'B736': B736Icon,
  'B737': B737Icon,
  'B738': B738Icon,
  'B739': B739Icon,
  'B742': B742Icon,
  'B744': B744Icon,
  'B748': B748Icon,
  'B752': B752Icon,
  'B753': B753Icon,
  'B762': B762Icon,
  'B772': B772Icon,
  'B773': B773Icon,
  'B788': B788Icon,
  'B789': B789Icon,
  'BE58': BE58Icon,
  'C25C': C25CIcon,
  'C130': C130Icon,
  'C152': C152Icon,
  'C172': C172Icon,
  'C182': C182Icon,
  'C208': C208Icon,
  'C700': C700Icon,
  'C750': C750Icon,
  'CL60': CL60Icon,
  'CRJ9': CRJ9Icon,
  'DA40': DA40Icon,
  'DA42': DA42Icon,
  'DA62': DA62Icon,
  'DC3': DC3Icon,
  'DC6': DC6Icon,
  'DH8D': DH8DIcon,
  'DHC2': DHC2Icon,
  'E17x': E17xIcon,
  'E19x': E19xIcon,
  'E55P': E55PIcon,
  'E75x': E75xIcon,
  'EC45': EC45Icon,
  'F14': F14Icon,
  'F28': F28Icon,
  'F35': F35Icon,
  'GLF5': GLF5Icon,
  'H160': H160Icon,
  'MD8x': MD8xIcon,
  'MD11': MD11Icon,
  'PC12': PC12Icon,
  'R66': R66Icon,
  'SF50': SF50Icon,
  'T134': T134Icon,
  'T154': T154Icon,
  'TBM8': TBM8Icon,
  'TBM9': TBM9Icon,
  'CONC': CONCIcon,
  'SR22': SR22Icon,
  'SR71': SR71Icon,
  'EUFI': EUFIIcon,
  'defaultIcon': defaultIconImg,  // Fallback icon
};








const aircraftSizeMapping = {
  // Large Aircraft
  'A300': 'large',
  'A306': 'large',
  'A310': 'large',
  'A332': 'large',
  'A333': 'large',
  'A343': 'large',
  'A346': 'large',
  'A359': 'large',
  'A388': 'large',
  'B742': 'large',
  'B744': 'large',
  'B748': 'large',
  'B772': 'large',
  'B773': 'large',
  'B77L': 'large',
  'B77W': 'large',
  'B788': 'large',
  'B789': 'large',
  'B78X': 'large',
  'CONC': 'large',
  'MD11': 'large',
  'SR71': 'large',
  
  // Medium Aircraft
  'A318': 'medium',
  'A319': 'medium',
  'A320': 'medium',
  'A32N': 'medium',
  'A321': 'medium',
  'A21N': 'medium',
  'AT7x': 'medium',
  'AT76': 'medium',
  'B703': 'medium',
  'B712': 'medium',
  'B722': 'medium',
  'B733': 'medium',
  'B734': 'medium',
  'B736': 'medium',
  'B737': 'medium',
  'B73W': 'medium',
  'B738': 'medium',
  'B739': 'medium',
  'B752': 'medium',
  'B753': 'medium',
  'B762': 'medium',
  'CRJ7': 'medium',
  'CRJ9': 'medium',
  'DH8D': 'medium',
  'DC6': 'medium',
  'E17x': 'medium',
  'E170': 'medium',
  'E175': 'medium',
  'E19x': 'medium',
  'E190': 'medium',
  'E195': 'medium',
  'E75x': 'medium',
  'F28': 'medium',
  'H160': 'medium',
  'MD8x': 'medium',
  'T134': 'medium',
  'T154': 'medium',
  'YK40': 'medium',
  'defaultIcon': 'medium',
  
  // Small Aircraft
  'C700': 'small',
  'C750': 'small',
  'C510': 'small',
  'C25C': 'small',
  'C25B': 'small',
  'C25A': 'small',
  'E55P': 'small',
  'GLF5': 'small',
  'GLF6': 'small',
  'PC12': 'small',
  'R66': 'small',
  'R44': 'small',
  'TBM8': 'small', // potential for xs
  'TBM9': 'small', // potential for xs
  'CL60': 'small',
  'LEA4': 'small',
  'EC45': 'small',
  'H145': 'small',
  'H47': 'small',
  'BE20': 'small',
  'B350': 'small', // potential for xs
  'DC3': 'small',
  'PRPR': 'small',
  'KODI': 'small',
  'C337': 'small',
  'B1': 'small',
  'MG21': 'small',
  'SU27': 'small',
  'SU95': 'small',
  'HDJT': 'small',
  'AN24': 'small',
  'AT75': 'small',
  'H075': 'small',
  'T144': 'small',
  'EUFI': 'small', // Fighter Jet
  'F35': 'small', // Fighter Jet
  'F14': 'small', // Fighter Jet







  // Extra Small Aircraft
  'C152': 'extrasmall',
  'C172': 'extrasmall',
  'BE36': 'extrasmall',
  'C182': 'extrasmall',
  'C208': 'extrasmall',
  'DA40': 'extrasmall',
  'DA42': 'extrasmall',
  'DA62': 'extrasmall',
  'DHC2': 'extrasmall',
  'P06T': 'extrasmall',
  'P46T': 'extrasmall',
  'SR22': 'extrasmall',
  'BE76': 'extrasmall',
  'C207': 'extrasmall',
  'C170': 'extrasmall',
  'BE35': 'extrasmall',
  'BE58': 'extrasmall',
  'SF50': 'extrasmall',





  
  // Default / Fallback
  'Unknown': 'small',
};




// Size classification and scaling factors
const aircraftSizeClassifications = {
  'extrasmall': 0.30,
  'small': 0.45, // default is 0.55
  'medium': 0.60, // default is 0.70
  'large': 0.65, // default is 0.75
};


export { aircraftIcons, aircraftTypeMappings, aircraftSizeMapping, aircraftSizeClassifications };