import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/site/NavBar';
import VatsimMap from './components/map/VatsimMap';
import FlightsPage from './components/site/FlightsPage';
import AboutPage from './components/site/AboutPage';
import ContactPage from './components/site/ContactPage';

function VatLoomApp() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="map" element={<VatsimMap />} />
        <Route path="flights" element={<FlightsPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="/" element={<Navigate to="map" />} />
      </Routes>
    </Router>
  );
}

export default VatLoomApp;
