import React from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="map" className="navbar-logo">
          VatL<span className="white-o">o</span><span className="white-o">o</span>m
        </Link>
        <ul className="navbar-left-menu">
          <li><Link to="flights">Flights</Link></li>
          <li><Link to="controllers">Controllers</Link></li>
          <li><Link to="events">Events</Link></li>
        </ul>
        <ul className="navbar-menu">
          <li><Link to="about">About</Link></li>
          <li><Link to="contact">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
